<div class="bg-white" style="min-height: 100vh;">
  <div class="candidate-list-wrapper w-full font-poppins bg-white">
    <div class="candidate-list-header">
      <div class="flex flex-col mb-2">
        <div class="btn-group mb-3 flex items-center justify-between">
          <button (click)="showFilters = !showFilters" class="px-4 py-1 bg-blue-700 white rounded-lg text-base w-fit text-white">
            <span class="filter-btn">Filters</span>
          </button>
          <div *appHasPermission="'recruiter_create'" class="recruiter_create-wr-btn flex-grow-1">
            <a
              class="recruiter_create_btn text-black font-medium rounded-lg cursor-pointer white-space-nowrap"
              data-toggle="modal"
              data-target="#candidate_modal"
              (click)="openForm()"
            >
              ADD CANDIDATE
            </a>
          </div>
        </div>
        @if(showFilters){
          <div class="flex flex-row gap-2 justify-between w-full pb-2">
              <app-input
                [isFilterInput]="true"
                [label]="'Name'"
                [value]="filter?.name"
                (onChange)="setFilter({ name: $event })"
                [debounceEnable]="true"
                [disableValidation]="true"
                [hightLightIfNotEmpty]="true"
                (onRemove)="setFilter({ name: ''})"
                [display]="{ remove: true }"
                [idInfo]="'candidate-name-filter'"
                class="w-full"
              ></app-input>
              <app-input
                [isFilterInput]="true"
                [label]="'Phone'"
                [type]="'phone'"
                [disableValidation]="true"
                [value]="filter?.phone"
                (onSave)="setFilter({ phone: $event })"
                [options]="{padding_left_input: false}"
                [debounceEnable]="true"
                [hightLightIfNotEmpty]="true"
                (onRemove)="setFilter({ phone: '' })"
                [display]="{ remove: true }"
                [idInfo]="'candidate-phone-filter'"
                class="w-full"
              ></app-input>
              <app-input
                [isFilterInput]="true"
                [label]="'Email'"
                [value]="filter?.email"
                (onSave)="setFilter({ email: $event })"
                [debounceEnable]="true"
                [hightLightIfNotEmpty]="true"
                (onRemove)="setFilter({ email: '' })"
                [display]="{ remove: true }"
                [idInfo]="'candidate-email-filter'"
                class="w-full"
              ></app-input>
              <app-multi-select
                [label]="'Job Positions'"
                class="w-full"
                [idInfo]="'candidate-job'"
                [placeholder]="'Job Positions'"
                [options]="{selectAll: true}"
                [data]="jobPositions"
                [visibleCount]="2"
                [value]="filter?.jobPositions"
                [keyName]="{key:'key', name: 'name'}"
                (onSave)="setFilter({'jobPositions': $event})"
              ></app-multi-select>
              <div class="w-full">
                <app-date [label]="'Start Date'" [startKey]="'startDate'" [parent]="{startDate: filter.startDate}"
                  [options]="{ timezone: true }" (onSave)="setFilter({startDate: $event.startDate})">
                </app-date>
              </div>
              <div class="w-full">
                <app-date [label]="'End Date'" [startKey]="'endDate'" [parent]="{endDate: filter.endDate}"
                  [options]="{ timezone: true }" (onSave)="setFilter({endDate: $event.endDate})">
                </app-date>
              </div>
          </div>
          <button class="reset-filter-btn mb-2" (click)="resetFilters()">Reset</button>
        }
      </div>
      <div class="flex justify-between items-center gap-2">
        <div class="candidate-filters w-full">
          <app-filters
            [status]="stageTypes"
            [property]="'stage'"
            [options]="{ upperCase: true }"
            [filters]="filter$ | async"
            (filterSet)="setFilter($event)"
          ></app-filters>
        </div>
      </div>
    </div>
    <div>
      <table class="custom-table w-full">
        <thead>
          <tr>
            <th>
              <span>NAME</span>
              <button (click)="setFilter({dir: filter?.dir === 'asc' ? 'desc' : 'asc', prop: 'contact.name'})">
                <i style="color: #bdbdbd;" class="fa-solid ml-2"
                [ngClass]="{
                  'fa-chevron-down': (filter?.prop === 'contact.name' && filter?.dir === 'desc')  || filter?.prop !== 'contact.name',
                  'fa-chevron-up': (filter?.prop === 'contact.name' && filter?.dir === 'asc')
                  }"
                ></i>
              </button>
            </th>
            <th>
              <span>APPLIED DATE</span>
              <button (click)="setFilter({dir: filter?.dir === 'asc' ? 'desc' : 'asc', prop: 'createdAt'})">
                <i style="color: #bdbdbd;" class="fa-solid ml-2"
                [ngClass]="{
                  'fa-chevron-down': (filter?.prop === 'createdAt' && filter?.dir === 'desc')  || filter?.prop !== 'createdAt',
                  'fa-chevron-up': (filter?.prop === 'createdAt' && filter?.dir === 'asc')
                  }"
                ></i>
              </button>
            </th>
            <th>
              <span>JOB</span>
              <button (click)="setFilter({dir: filter?.dir === 'asc' ? 'desc' : 'asc', prop: 'job'})">
                <i style="color: #bdbdbd;" class="fa-solid ml-2"
                [ngClass]="{
                  'fa-chevron-down': (filter?.prop === 'job' && filter?.dir === 'desc')  || filter?.prop !== 'job',
                  'fa-chevron-up': (filter?.prop === 'job' && filter?.dir === 'asc')
                  }"
                ></i>
              </button>
            </th>
            <th>
              <span>STAGE</span>
              <button (click)="setFilter({dir: filter?.dir === 'asc' ? 'desc' : 'asc', prop: 'stageType'})">
                <i style="color: #bdbdbd;" class="fa-solid ml-2"
                [ngClass]="{
                  'fa-chevron-down': (filter?.prop === 'stageType' && filter?.dir === 'desc')  || filter?.prop !== 'stageType',
                  'fa-chevron-up': (filter?.prop === 'stageType' && filter?.dir === 'asc')
                  }"
                ></i>
              </button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for(row of candidates; track row._id){
            <tr>
              <td>
                <a class="recruiter-link" [routerLink]="['/recruiter/candidate', row._id]" routerLinkActive="router-link-active">
                  {{ row.contact.name }}
                </a>
              </td>
              <td>
                <span class="recruiter-link text-gray-600">
                  {{ row.createdAt | date: 'MM/dd/YY'}}
                </span>
              </td>
              <td>
                <div class="flex items-center">
                  <span class="job-type">{{ row.job.name }}</span>
                  @if(row?.haulType){
                    <span class="job-type ml-2">{{ row.haulType }}</span>
                  }
                  <span class="candidate-rating flex">
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 0}" class="fa-solid fa-star"></i>
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 1}" class="fa-solid fa-star"></i>
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 2}" class="fa-solid fa-star"></i>
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 3}" class="fa-solid fa-star"></i>
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 4}" class="fa-solid fa-star"></i>
                  </span>
                </div>
              </td>
              <td>{{ row.stage }}</td>
              <td>
                <a class="view recruiter-link" [routerLink]="['/recruiter/candidate', row._id]" routerLinkActive="router-link-active">
                  View
                </a>
              </td>
            </tr>
          }
        </tbody>
      </table>
      <div class="w-full mt-3">
        @if(candidates?.length){
          <app-pagination class="pag-wrapper" (pageChange)="setFilter({ page: $event})" [total]="total" [limit]="filter?.limit" [currentPage]="filter?.page"></app-pagination>
        }
      </div>
  </div>
</div>
