import { candidateJobPositions } from '@app/shared/services/candidate.service';
import { Component, OnInit, Input, OnDestroy, } from '@angular/core'

import * as fromCoreStore from 'app/core/store'
import { OpenModal } from '@app/modals/store/actions/layout.actions'

import { Candidate, User } from '@app/shared'
import { Observable, Subject } from 'rxjs'
import { Store } from '@ngrx/store'
import { ToastHelper } from '@app/core/services'
import { tap, takeUntil } from 'rxjs/operators'
import { popupService } from '@app/shared/services/popup.service'
import { ActivatedRoute } from '@angular/router'

import { GET_DEFAULT_FILTER } from '@app/shared/store/reducers/candidate.reducers'
import moment from 'moment'


@Component({
    selector: 'app-candidate-list',
    templateUrl: './candidate-list.component.html',
    styleUrls: ['./candidate-list.component.css'],
})
export class CandidateListComponent implements OnInit, OnDestroy{

    @Input()
    candidates: Candidate[] = []
    pagesCount
    @Input()
    currentUser: User
    filter$: Observable<any>
    filter: any
    destroyed$ = new Subject<boolean>()
    total
    stageTypes = [
        { name: 'All', key:'All', count: 0},
        { name: 'New', key: 'New', count: 0 },
        { name: 'Active', key: 'Active', count: 0 },
        { name: 'On-Hold', key: 'On-Hold', count: 0 },
        { name: 'Hired', key: 'Hired', count: 0},
        { name: 'Rejected', key: 'Rejected', count: 0 }
    ]
    showFilters: boolean = true

    constructor(
        private store: Store<fromCoreStore.State>,
        public notify: ToastHelper,
        private popupService: popupService,
        private route: ActivatedRoute,
    ){}

    statuses = ['Candidates']

    jobPositions = candidateJobPositions

    ngOnInit() {

        this.filter$ = this.store.select(fromCoreStore.selectCandidateFilter)
        .pipe(tap(f => {
            this.filter = f
            this.store.dispatch(new fromCoreStore.GetCandidates(f))
        }))

        this.store.select(fromCoreStore.selectCandidateCountsState).pipe(takeUntil(this.destroyed$)).subscribe(counts => {

            for (let item of this.stageTypes) {
                item.count = 0
            }
            for (let type of this.stageTypes) {
                for (let item of counts) {
                    type.name == item._id ? type.count = item.count : null
                    if (this.filter?.stage == null) {
                        type.name == 'All' ? this.total = type.count : null
                    } else {
                        type.name == this.filter.stage ? this.total = type.count : null
                    }
                }
            }
        })
    }

    resetFilters = () => {
        this.store.dispatch(new fromCoreStore.SetCandidatesFilter(GET_DEFAULT_FILTER()))
    }

    setFilter = (filter: {[key: string]: any}) => {
        for (let stage of this.stageTypes) {
            filter.stage == stage.name ? this.total = stage.count : null
        }
        if(filter.endDate){
            filter.endDate = moment(filter.endDate).endOf('day').format()
        }
        this.store.dispatch(new fromCoreStore.SetCandidatesFilter({ ...this.filter, ...filter }))
    }

    openForm = () => this.store.dispatch(new OpenModal({
        type: 'CANDIDATE_FORM',
        props: {
          windowClass : "candidate-form-modal"
        },

    }))
    onDelete = async (data) => {
        if (await this.popupService.confirm(`Are you sure you want to delete ${data.contact.name}?`)) {
            this.store.dispatch(new fromCoreStore.DeleteCandidate({ id: data._id, item: data.creator._id}))
        }
    }
    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}



