import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import { NgxPopperjsTriggers, NgxPopperjsPlacements } from 'ngx-popperjs'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { candidateJobPositions, contactStages } from '@app/shared/services/candidate.service'

import {
    User,
    Candidate,
    Note,
    FOLLOW_UP_RESOURCE
} from '@app/shared/models'
import { NotesComponent } from '@app/shared'
import { popupService } from '@app/shared/services/popup.service'
import { Router } from '@angular/router';

@Component({
    selector: 'app-candidate-details',
    templateUrl: './candidate-details.component.html',
    styleUrls: ['./candidate-details.component.scss'],
})
export class CandidateDetailsComponent implements OnInit, OnDestroy {
    @ViewChild(NotesComponent, { static: false })
    notesComp: NotesComponent
    @ViewChild('editInfoBtn') editInfoBtn: any;
    @Output()
    onUpdate = new EventEmitter<Candidate>()
    @Input()
    currentUser: User
    @Input()
    users
    @Input()
    set candidate(val){
        if(val){
            this._candidate = val
            this.splitQuestions(this._candidate.questions)
        }
    }
    get candidate() {
        return this._candidate
    }

    linkNotes = 'notes'

    edit = false
    NgxPopperjsTriggers = NgxPopperjsTriggers
    NgxPopperjsPlacements = NgxPopperjsPlacements
    _candidate
    generalQuestions = []
    additionalQuestions = []
    filter

    destroyed$ = new Subject<boolean>()
    FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE
    jobPositions = candidateJobPositions
    stageStatuses = contactStages
    constructor(private store: Store<fromCoreStore.State>, private router: Router, private popupService: popupService, private changeDetector: ChangeDetectorRef, private el: ElementRef, private renderer: Renderer2) { }


    ngOnInit(): void {
        this.store.select(fromCoreStore.selectCandidateFilter).pipe(takeUntil(this.destroyed$)).subscribe(filter => {
            this.filter = filter
        })
    }



    closePopper() {
        if (this.editInfoBtn) {
          this.editInfoBtn.hide();
        }
      }

      onDelete = async (data) => {
        if (await this.popupService.confirm(`Are you sure you want to delete ${data.contact.name}?`)) {
            this.store.dispatch(new fromCoreStore.DeleteCandidate({ id: data._id, item: data.creator._id, filter: this.filter}))
            this.router.navigate(['/recruiter']);
        }
    }

    splitQuestions(questions){
        this.generalQuestions = questions.filter(q=>q.question==='Do you have a CDL Class-A?'||q.question==='How much CDL-A driving experience do you have?'||q.question==='How much auto hauling experience do you have?'||q.question==='Additional Information')
        this.additionalQuestions = questions.filter(q=>q.question!=='Do you have a CDL Class-A?'&&q.question!=='How much CDL-A driving experience do you have?'&&q.question!=='How much auto hauling experience do you have?'&&q.question !== 'Additional Information')
    }

    patchCandidate = (candidate: Partial<Candidate> | any) => {
        this.store.dispatch(
            new fromCoreStore.UpdateCandidate({
                item: {
                    id: this.candidate._id,
                    changes: {_id:this.candidate._id, ...candidate}
                },
                dispatch: true
            })
        )
    }

    setStage(value) {
        this.patchCandidate({ ...this.candidate, stage: value })
    }
    setRating(event){
        if(!event)return
        this.candidate.contact = {
            ...this.candidate.contact,
            rating: event
        }
        this.patchCandidate(this.candidate)
    }
    updateDate($event){
        this.patchCandidate({contact:{...this.candidate.contact, dob:$event.dob}, _id:this.candidate._id})
    }
    addQuestion(event, id) {
        let index = this.candidate.questions.findIndex((el)=>el._id === id)
        this.candidate.questions[index].answer = event.target.value
        this.store.dispatch(
            new fromCoreStore.UpdateCandidate({
                item: {
                    id: this.candidate._id,
                    changes: this.candidate
                },
                dispatch: false
            })
        )
    }
    updateCandidateContactNotify(contact) {
        console.log(contact)
        Object.keys(contact).forEach(key => contact[key] === undefined ? delete contact[key] : {});
        this.patchCandidate({ ...this.candidate, contact: {...this.candidate.contact, ...contact }})
    }
    updateCandidateContact(event) {
        delete event._id
        console.log(event, { ...this.candidate, contact: { ...this.candidate.contact, ...event } })
        this.patchCandidate({ ...this.candidate, contact: { ...this.candidate.contact, ...event } })
    }
    ngOnDestroy() {
        this.destroyed$.complete()
    }
}
